import React from 'react';
import { BsArrowRight } from 'react-icons/bs';
import { Link } from 'react-router-dom';
import usePositionData from './PositionConfig';
import parse from 'html-react-parser';

const Position = ({ SectionData }) => {
  const positions = usePositionData();
  let section3 = '';
  let title = '';

  if (SectionData) {
    section3 = SectionData[3];
    title = SectionData[3][0]?.SectionTitle_Section3;
  }

  return (
    <div className='GlobalContainer'>
      <div className='container'>
        <div className='row'>
          <div className='col-md-12' data-aos='fade-up'>
            <div className='GlobalHeading'>
              <div className='titleBig'>
                {title === '' ? title : 'OPEN POSITIONS'}
              </div>
              <h2>{title === '' ? title : 'OPEN POSITIONS'}</h2>
            </div>
          </div>
          <div className='col-md-12'>
            {positions.length === 0 ? (
              <p className='text-center py-5 fs-3'>"No openings yet"</p>
            ) : (
              positions.map((details, index) => (
                <div
                  className='openPositionBox'
                  data-aos='fade-left'
                  key={index}
                >
                  <div className='openPositionBoxTitle d-flex flex-row justify-content-between gap-3 mb-3'>
                    <div>
                      <h3> {details.JobPosition_Section3} </h3>
                      <span>{details.JobLocation_Section3}</span>
                      {/* <Link
                        className='btn GrayBtn'
                        to={`/careers/apply/${details?.key}`}
                      >
                        <BsArrowRight />
                      </Link> */}
                    </div>
                    <div>
                      <Link
                        className='btn BlackBtn mr-4'
                        to={`/careers/apply/${details?.key}`}
                      >
                        Apply <BsArrowRight />
                      </Link>
                    </div>
                  </div>
                  <div className='openPositionBoxInfo'>
                    <p>{parse(details.JobDescription_Section3)}</p>
                  </div>
                </div>
              ))
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Position;
