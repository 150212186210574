import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

// Keep application related configs in this file

// all the page urls of the website
export const PAGE_URL = {
  HOMEPAGE: '/',
  ABOUT: '/about-outmazed-design',
  SERVICE: '/interiors-design-services',
  SERVICE_INTERIOR: '/interiors-design-services#Interior',
  SERVICE_FURNISH: '/interiors-design-services#Furnish',
  SERVICE_FIT_OUT: '/interiors-design-services#Fits',
  SERVICE_PROJECT_MANAGE: '/interiors-design-services#Project',
  SERVICE_OUTDOOR: '/interiors-design-services#Outdoor',
  SERVICE_RENOVATION: '/interiors-design-services#Renovation',
  SERVICE_MAINTINANCE: '/interiors-design-services#Maintinance',
  CONTACT_US: '/contact-us',
  CAREERS: '/careers',
  APPLY_JOB: '/careers/apply/:jobTitle',
  PROJECTS: '/interior-design-projects',
  PRIVACY: '/privacy-policy',
  BLOGS: '/blogs',
  FAQ: '/interior-design-faq',
  GET_QUOTE: '/get-quote',
  FLIP_BOOK: '/flip-book/:id',
  BLOG_DETAILS: '/blogs/:blogTitle',
  PROJECT_DETAILS: '/interior-design-projects/:projectId',
  THEME: '/interior-design-themes',
  THEME_MODERN: '/interior-design-themes#modern',
  THEME_CLASSICAL: '/interior-design-themes#classical',
  THEME_POST_MODERN: '/interior-design-themes#post-modern',
  THEME_ABSTRACT: '/interior-design-themes#abstract',
  TERMS_OF_USE: '/terms-and-conditions',
  NOT_FOUND: '/page-not-found',
  THANK_YOU: '/thank-you',
};

export const PAGE_URL_REGEX = {
  SHIPMENT_TRACKING: /^\/shipment\/tracking\/[0-9]+$/i,
};

export const LANGUAGES = {
  en: 'English',
};

export const DEVICE_SIZES = {
  tablet: 768,
  laptop: 992,
  desktop: 1200,
};

export const DEVICE_TYPE = {
  MOBILE: 'MOBILE',
  TABLET: 'TABLET',
  LAPTOP: 'LAPTOP',
  DESKTOP: 'DESKTOP',
};

export const DEVICES = {
  sMobile: `(max-width: 480px)`,
  mobile: `(max-width: ${DEVICE_SIZES.tablet}px)`,
  tablet: `(min-width: ${DEVICE_SIZES.tablet}px) and (max-width:${DEVICE_SIZES.laptop}px)`,
  laptop: `(min-width: ${DEVICE_SIZES.laptop}px) and (max-width:${DEVICE_SIZES.desktop}px)`,
  desktop: `(min-width: ${DEVICE_SIZES.desktop}px)`,
};

export const EventBus = {
  UPDATE_META_INFORMATION: 'UPDATE_META_INFORMATION',
};

// Scroll to the top of page whenever the pathname changes
const ScrollToTop = () => {
  // Extracts pathname property(key) from an object
  const { pathname } = useLocation();

  // Automatically scrolls to top whenever pathname changes
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return null;
};

export default ScrollToTop;
