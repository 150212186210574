// Keep all the api related configs such as endpoints in this file
const baseUrl = 'https://isourse.com:1047/'; //production api's
// const baseUrl = 'https://demo.isoping.com:7344'; //test api's
// const baseUrl = 'https://192.168.222.40:6642'; //development api's

// Endpoints for getting cms data
export const GET_API_ENDPOINTS = {
  SECTIONWISE: `${baseUrl}/GetSectionWise`,
  GET_AREAS: `${baseUrl}/ContactUs/Area/Get`,
};

export const POST_API_ENDPOINTS = {
  ADD_QUERIES: `${baseUrl}/ContactUs/Queries/Add`,
  ADD_APPLICATIONS: `${baseUrl}/Careers/Section4/Add`,
};
